<template>
  <!-- 线路管理 -->
  <div class="lineManage">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">线路代码：</div>
        <div class="col-2">
          <el-input
            v-model="queryForm.code"
            clearable
            placeholder="请输入线路代码"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">线路名称：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.name"
            clearable
            placeholder="请输入线路名称"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">起点站：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.startStationId"
            clearable
            filterable
            placeholder="请输入搜索选择"
            :filter-method="startStationFilter"
            remote
          >
            <el-option
              v-for="item in stationStartList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">终点站：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.endStationId"
            clearable
            filterable
            placeholder="请输入搜索选择"
            :filter-method="endStationFilter"
            remote
          >
            <el-option
              v-for="item in stationEndList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">所属公司：</div>
        <div class="col-2 col-2-a">
          <companySelect
            ref="companyRef"
            :clearable="true"
            :company-tree="companyList"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box" style="width: 36%">
        <el-button type="primary" size="small" @click="searchLine"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="exportFun"
          >导出</el-button
        >
        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="syncLine"
          >同步线路</el-button
        >
      </div>
    </div>
    <Table
      v-show="!planLineShow"
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
      :operation-width="250"
    >
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="setPointFun(scope.scopeRow)"
          >设置途经点</el-button
        >
        <el-button type="text" size="small" @click="planLineFun(scope.scopeRow)"
          >规划线路</el-button
        >
        <el-button
          type="text"
          size="small"
          sort="primary"
          @click="updateFun(scope.scopeRow)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="small"
          sort="primary"
          @click="deleteFun(scope.scopeRow)"
          >删除</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <charterPopup
      v-if="editorShow"
      width="500"
      :header-text="headerText"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <div class="contentPopup">
        <el-form
          ref="Form"
          :model="pointForm"
          :rules="rules"
          label-width="100px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item label="所属公司：" prop="code">
            <companySelect
              v-if="editorShow"
              ref="companyRefTwo"
              :company-tree="companyList"
              :valuename="pointForm.companyName"
              :value="pointForm.companyId"
              @getValue="getAddGroupIdShow"
            ></companySelect>
          </el-form-item>
          <el-form-item label="票源：" prop="source">
            <el-select
              v-model="pointForm.operationMode"
              disabled="disabled"
              placeholder="请选择"
            >
              <el-option
                v-for="item in operationModeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客服电话：" prop="customerPhone">
            <div class="addNumberBox">
              <div
                v-for="(item, index) in pointForm.customerServicePhone"
                :key="index"
                style="position: relative"
              >
                <el-input
                  v-model.trim="pointForm.customerServicePhone[index]"
                  clearable
                  maxlength="11"
                  placeholder="请输入客服电话号码"
                  onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                ></el-input>
                <el-button
                  v-show="index >= 1"
                  class="reduce"
                  @click="reducecustomerFun(index)"
                  >-</el-button
                >
              </div>
              <el-button
                v-show="this.pointForm.customerServicePhone.length < 5"
                class="add"
                @click="addcustomerFun"
                >+</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
    <!-- 同步线路弹窗 -->
    <charterPopup
      v-if="sycnStrategyShow"
      width="500"
      :header-text="headerText"
      @closePopupFun="closePopuSycnpFun"
      @confirmFun="confirmFun"
    >
      <div class="contentPopup">
        <el-form
          ref="sycnForm"
          :model="sycnForm"
          :rules="rulesSycn"
          label-width="110px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item label="起点站名称：" prop="startStation">
            <el-input
              v-model="sycnForm.startStation"
              clearable
              placeholder="请输入起点站名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="终点站名称：" prop="endStation">
            <el-input
              v-model="sycnForm.endStation"
              clearable
              placeholder="请输入终点站名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="同步：" prop="sycnStrategy">
            <el-radio v-model="sycnForm.sycnStrategy" label="0"
              >精确同步</el-radio
            >
            <el-radio v-model="sycnForm.sycnStrategy" label="1"
              >模糊同步</el-radio
            >
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
    <!-- 设置途经点 -->
    <charterPopup
      v-if="lineStationShow"
      width="800"
      :header-text="headerText"
      :confirm-text="confirmText"
      @closePopupFun="closePopupStationFun"
      @confirmFun="confirmFun"
    >
      <my-table
        ref="myTable"
        :station-data="stationData"
        :is-operation-mode="isOperationMode"
        :station-start-list="stationAll"
      ></my-table>
    </charterPopup>
    <!-- 规划线路 -->
    <div v-if="planLineShow" class="plan-line">
      <div style="padding: 0 0 12px">
        <el-button size="small" @click="backTable">
          <i class="iconfont its_ba_fanhui" style="font-size: 14px"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="selfDisabled"
          @click="drawPolyline"
          >自定义线路</el-button
        >
        <el-button type="primary" size="small" @click="openPolyEditor"
          >编辑线路</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-s-order"
          @click="savePolyline"
          >保存</el-button
        >
      </div>
      <div class="map-box">
        <div id="map" class="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import stationTable from "./components/table.vue";
import { getCompanyTree } from "@/api/lib/api.js";
import {
  stationList,
  stationLineList,
  stationLineUpDate,
  stationLineSycn,
  stationLineExport,
  stationLineDelete,
  lineTrailInfo,
  lineStationList,
  lineStationUpdate,
  lineTrailEdit,
} from "@/api/lib/customizedBus.js";
export default {
  components: {
    companySelect,
    "my-table": stationTable,
  },
  data() {
    return {
      companyList: [],
      queryForm: {
        code: "",
        name: "",
        startStationId: "",
        endStationId: "",
        companyIds: [],
        currentPage: 1,
        pageSize: 10,
        businessType: 6, //业务类型：6-定制班线 7-村通客运
      },
      exportForm: {},
      stationStartList: [], //起始站点
      stationEndList: [], //终点站点
      tableData: [],
      total: 0,
      titleName: [
        {
          title: "线路代码",
          props: "code",
        },
        {
          title: "线路名称",
          props: "name",
        },
        {
          title: "起点站",
          props: "startStation",
          SpecialJudgment: (res) => {
            return res.name;
          },
        },
        {
          title: "终点站",
          props: "endStation",
          SpecialJudgment: (res) => {
            return res.name;
          },
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "票源",
          props: "operationMode",
          SpecialJudgment: (res) => {
            return res === 0 ? "平台" : "票务系统";
          },
        },
        {
          title: "客服电话",
          props: "customerServicePhone",
          SpecialJudgment: (res) => {
            let text = "";
            text = res.join(";");
            return text;
          },
        },
        {
          title: "启用",
          props: "status",
          width: 70,
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    status: params.status === 0 ? 1 : 0,
                  };
                  this.judgePointsAndPoly(obj);
                  // this.renderDataUpdateFun(obj);
                },
              },
            });
          },
        },
      ],
      headerText: "",
      editorShow: false,
      pointForm: null,
      operationModeList: [
        { id: 0, name: "平台" },
        { id: 1, name: "票务系统" },
      ],
      rules: {
        customerPhone: [
          {
            validator: this.customerPhoneFun,
            trigger: "blur",
          },
        ],
      },

      /**线路同步 */
      sycnForm: {
        startStation: "", //起点站名称
        endStation: "", //终点站名称
        sycnStrategy: "0", //同步策略：0-精确，1-模糊
      },
      sycnStrategyShow: false,
      rulesSycn: {
        startStation: [
          { required: true, message: "请输入起点站名称", trigger: "blur" },
        ],
        endStation: [
          { required: true, message: "请输入终点站名称", trigger: "blur" },
        ],
      },
      /**线路规划 */
      planLineShow: false,
      trailStations: [],
      mouseTool: null,
      pathArr: [],
      lineRow: null,
      polyline: null,
      selfDisabled: false,

      /**设置站点途经点 */
      lineStationShow: false,
      stationData: [],
      confirmText: "",
      isOperationMode: false, //是否是票务系统
      stationAll: [], //站点搜索选择
      lineId: "",
    };
  },
  mounted() {
    this.getCompanyList();
    this.queryStationList();
    this.searchLine();
  },
  methods: {
    judgePointsAndPoly(obj) {
      /**判断点是否在线上 */
      let { lineTrail, stations } = obj.lineTrail;
      if (lineTrail) {
        let path = JSON.parse(lineTrail);
        var flag = true;
        var arr = [];
        var m = 6; // m 为Polyline宽度的米数
        for (let i = 0; i < stations.length - 1; i++) {
          arr[i] = false;
          for (let j = 0; j < path.length - 1; j++) {
            // 判断 marker 是否在线段上，最后一个参数为 m米 的误差
            var inLine = AMap.GeometryUtil.isPointOnSegment(
              [stations[i].longitude, stations[i].latitude],
              path[j],
              path[j + 1],
              30
            );
            if (inLine) {
              arr[i] = true;
              continue;
            }
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i]) {
            flag = false;
            break;
          }
        }
        console.log("------------------------", flag);
        if (flag) {
          this.renderDataUpdateFun(obj);
        } else {
          this.$message.error("站点不在线路上，请检查！");
        }
      } else {
        this.$message.error("站点不在线路上，请检查！");
      }
    },
    backTable() {
      this.selfDisabled = false;
      this.planLineShow = false;
      this.pathArr = [];
      this.polyEditor = null;
      this.searchLine();
    },
    // 实例化地图
    initMap() {
      this.pathArr = [];
      this.map = new AMap.Map("map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
    },
    /* 绘制站点 */
    renderStation(stationArr) {
      let self = this;
      stationArr.map((item, i) => {
        if (item.longitude && item.latitude) {
          let content = "";
          if (item.latitude) {
            content = `<div style='width:5px;height:5px;border-radius:50%;background-color:#FFFFFF;border:2px solid #0B4FD5'></div>`;
          }
          let marker = new AMap.Marker({
            content: content,
            position: [item.longitude, item.latitude],
            offset: new AMap.Pixel(-5, -4),
          });
          marker.setLabel({
            offset: new AMap.Pixel(8, -10), //设置文本标注偏移量
            content: item.name,
            direction: "right", //设置文本标注方位
          });
          // marker.on('mouseover', function (e) {
          //   self.text = new AMap.Text({
          //     map: self.map,
          //     position: [item.longitude, item.latitude],
          //     text: item.name,
          //     offset: new AMap.Pixel(0, -12),
          //   })
          // })
          // marker.on('mouseout', function (e) {
          //   self.map.remove(self.text)
          // })
          marker.setMap(self.map);
        }
      });
      this.map.setFitView(); //放在视野中间
    },
    clearMap() {
      this.map.clearMap();
      this.pathArr = [];
    },
    //绘制本地公交路线
    lineSearchLocal_Callback(pathArr, companyName) {
      let path = [];
      if (pathArr.length === 0) {
        this.$message.warning("暂无线路数据");
        return;
      }
      let self = this;
      path = JSON.parse(pathArr);
      let polyline = new AMap.Polyline({
        path: path,
        strokeColor: self.color || "#27AD06",
        strokeOpacity: self.strokeOpacity || 0.8,
        strokeWeight: self.strokeWeight || 6,
        strokeDasharray: [25, 10],
        lineJoin: "round",
        lineCap: "round",
        cursor: "default",
        // extData: lineName
      });
      polyline.setMap(self.map);
      this.polyline = polyline;
      // self.readyAdd.push(lineName)
      self.pathArr = path;
      // 缩放地图到合适的视野级别
      // self.map.setFitView([polyline])
    },
    //自定义绘制路线
    drawPolyline() {
      let self = this;
      this.mouseTool = new AMap.MouseTool(this.map);
      if (this.polyline) {
        this.map.remove(this.polyline);
        this.polyline.setMap(null);
      }
      this.pathArr = [];
      this.polyEditor = null;
      this.mouseTool.polyline({
        strokeColor: self.color || "#27AD06",
        strokeOpacity: self.strokeOpacity || 0.8,
        strokeWeight: self.strokeWeight || 6,
        strokeStyle: "solid",
      });
      this.mouseTool.on("draw", function (e) {
        // event.obj 为绘制出来的覆盖物对象 e.obj.getPath() self.mouseTool.close();
        self.pathArr = e.obj.getPath();
        self.mouseTool.close();
      });
    },
    //编辑线路
    openPolyEditor(lineName) {
      this.selfDisabled = true; //禁用掉自定义线路
      let self = this;
      for (
        var marks = self.map.getAllOverlays(), i = 0;
        i < marks.length;
        i++
      ) {
        if ("Overlay.Polyline" == marks[i].CLASS_NAME) {
          let polyEditor = new AMap.PolyEditor(self.map, marks[i]);
          polyEditor.open();
          polyEditor.on("end", function (event) {
            // console.log('end',event.target.getPath())
            // self.getPath(marks[i].getPath());
            self.pathArr = event.target.getPath();
            // event.target 即为编辑后的折线对象
          });
          polyEditor.on("addnode", function (event) {
            // console.log('触发事件：addnode')
            self.pathArr = event.target.getPath();
          });

          polyEditor.on("adjust", function (event) {
            // console.log('触发事件：adjust')
            self.pathArr = event.target.getPath();
          });
          this.polyEditor = polyEditor;
        }
      }
    },
    savePolyline() {
      if (this.pathArr.length < 2) {
        this.$message.warning("请先绘制路线");
        return;
      }
      if (this.polyEditor) this.polyEditor.close();
      const data = {
        id: this.lineId,
        lineTrail: JSON.stringify(this.pathArr),
      };
      lineTrailEdit(data)
        .then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("保存成功");
            this.selfDisabled = false;
            this.planLineFun(this.lineRow);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**设置线路途经点 */
    setPointFun(scope) {
      lineStationList({ lineId: scope.id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.stationData = res.data;
          this.lineId = scope.id;
          if (scope.operationMode === 0) {
            //平台
            this.isOperationMode = false;
            this.confirmText = "更新";
            this.stationData.push({
              createTime: null,
              id: null,
              lineId: scope.id,
              price: "",
              serialNumber: res.data.length + 1,
              stationCode: "",
              stationId: null,
              stationName: "",
              updateTime: null,
              canInput: true, //可输入
              isNew: true,
            });
          } else {
            this.confirmText = "确认";
            this.isOperationMode = true; //票务系统不可更改站点和票价
          }
          this.lineStationShow = true;
          this.selfDisabled = false;
          this.headerText = "设置途经点";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    closePopupStationFun() {
      this.lineStationShow = false;
      this.headerText = "";
      this.stationData = [];
      this.lineId = "";
    },
    /**线路同步 */
    closePopuSycnpFun() {
      this.sycnStrategyShow = false;
      this.headerText = "";
      this.sycnForm = {
        startStation: "",
        endStation: "",
        sycnStrategy: "0",
      };
    },
    // 客服电话校验规则
    customerPhoneFun(rule, value, callback) {
      let reg = /^(([0][1-9]{2,3}[0-9]{5,10})|(1(3|4|5|6|7|8|9)\d{9}))$/;
      if (
        this.pointForm.customerServicePhone.length == 1 &&
        this.pointForm.customerServicePhone[0] == ""
      ) {
        callback();
      } else {
        let con = true;
        for (let j = 0; j < this.pointForm.customerServicePhone.length; j++) {
          if (reg.test(this.pointForm.customerServicePhone[j]) == false) {
            callback("客服电话号码格式错误");
            con = false;
            break;
          }
        }
        con && callback();
      }
    },
    // 点击添加客服电话
    addcustomerFun() {
      const len = this.pointForm.customerServicePhone.length - 1;
      if (this.pointForm.customerServicePhone[len].trim() == "") {
        this.$message.error("请输入客服号码后再进行添加");
      } else {
        this.pointForm.customerServicePhone.push("");
      }
    },
    // 点击去掉客服电话号码
    reducecustomerFun(index) {
      this.pointForm.customerServicePhone.splice(index, 1);
    },
    closePopupFun() {
      this.editorShow = false;
      this.headerText = "";
    },
    updateFun(scope) {
      console.log(scope);
      this.editorShow = true;
      this.headerText = "线路编辑";
      this.pointForm = this.deepClone(scope);
      if (scope.customerServicePhone.length == 0) {
        this.pointForm.customerServicePhone[0] = "";
      }
      this.$nextTick(() => {
        this.$refs.companyRefTwo.initHandle();
      });
    },
    confirmFun() {
      if (this.editorShow) {
        this.pointForm.customerServicePhone.map((item) => {
          item = item.replace(".", "");
        });
        //编辑
        this.$refs.Form.validate((valid) => {
          if (valid) {
            this.renderDataUpdateFun(this.pointForm);
          }
        });
      }
      if (this.sycnStrategyShow) {
        //线路同步
        this.$refs.sycnForm.validate((v) => {
          if (v) {
            const data = {
              ...this.sycnForm,
            };
            data.sycnStrategy = Number(this.sycnForm.sycnStrategy);
            stationLineSycn(data).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("线路同步成功！");
                this.closePopuSycnpFun();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        });
      }
      if (this.lineStationShow) {
        //设置站点途经点
        if (this.isOperationMode) {
          this.closePopupStationFun();
        } else {
          let flag = true;
          const mapData = this.$refs.myTable.tableData;
          let updateStationData = [...mapData];
          if (mapData.length >= 1) {
            mapData.map((item, index) => {
              if (item.isNew) {
                updateStationData.splice(index, 1);
              }
              if ((!item.stationName || !item.price) && !item.isNew) {
                flag = false;
              }
            });
          }
          /**判断价格是不是递增 */
          for (let i = 0; i < mapData.length - 2; i++) {
            if (mapData[i].price > mapData[i + 1].price) {
              this.$message.warning("请检查票价是否正确！");
              return;
            }
          }
          if (flag) {
            lineStationUpdate({
              lineId: this.lineId,
              lineStationRelations: updateStationData,
            }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("更新成功！");
                this.closePopupStationFun();
                this.searchLine();
              } else {
                this.$message.error(res.code);
              }
            });
          } else {
            this.$message.warning("请检查途经点名称和票价填写！");
          }
        }
      }
    },
    getAddGroupIdShow(v) {
      this.pointForm.companyId = v;
    },

    planLineFun(scope) {
      this.lineRow = scope;
      this.lineId = scope.id;
      lineTrailInfo({ id: scope.id }).then((res) => {
        if (res.code === "SUCCESS") {
          /**需要判断，存在途经点才能规划线路 */
          if (res.data.stations.length > 0) {
            this.planLineShow = true; //地图页面展示
            // this.trailStations=res.data.stations;
            this.$nextTick(() => {
              this.initMap();
              //绘制站点
              this.renderStation(res.data.stations);
              if (res.data.lineTrail) {
                //绘制线路
                this.lineSearchLocal_Callback(res.data.lineTrail);
              }
            });
          } else {
            this.$message.error("请先添加途经点！");
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deleteFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          stationLineDelete({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.searchLine();
            }
          });
        })
        .catch(() => {});
    },
    exportFun() {
      stationLineExport(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "定制班线线路表" + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    reset() {
      this.$refs.companyRef.initHandle();
      this.queryForm = {
        code: "",
        name: "",
        startStationId: "",
        endStationId: "",
        companyIds: [],
        currentPage: 1,
        pageSize: 10,
        businessType: 6, //业务类型：6-定制班线 7-村通客运
      };
    },
    //同步线路
    syncLine() {
      this.sycnStrategyShow = true;
      this.headerText = "线路同步";
    },
    //编辑
    renderDataUpdateFun(obj) {
      stationLineUpDate(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.searchLine();
          this.closePopupFun();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    queryStationList() {
      stationList().then((res) => {
        if (res.code === "SUCCESS") {
          this.stationStartList = res.data || [];
          this.stationEndList = res.data || [];
          this.stationAll = res.data || [];
        }
      });
    },
    //起始站点搜索
    startStationFilter(query) {
      if (query !== "") {
        setTimeout(() => {
          let data = { keyWord: query };
          stationList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.stationStartList = res.data;
            }
          });
        }, 200);
      } else {
        this.stationStartList = [];
      }
    },
    endStationFilter(query) {
      if (query !== "") {
        setTimeout(() => {
          let data = { keyWord: query };
          stationList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.stationEndList = res.data;
            }
          });
        }, 200);
      } else {
        this.stationEndList = [];
      }
    },
    // 企业回显
    getAddGroupId(v) {
      this.queryForm.companyIds = v ? [v] : [];
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    searchLine() {
      this.exportForm = this.deepClone(this.queryForm);
      stationLineList(this.queryForm).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.queryForm.currentPage = val;
      this.searchLine();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.amap-marker-label {
  border: 1px solid transparent;
  padding: 9px;
}
.stationPopup {
  padding: 32px 72px;
  height: 50vh;
}
.plan-line {
  width: 100%;
  height: 100%;
  background: #f7f8f9;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 22;
  .map-box {
    border-radius: 16px;
    background: #ffffff;
    padding: 10px;
    height: calc(100% - 66px);
    .map {
      width: 100%;
      height: 100%;
    }
  }
}
.addNumberBox {
  position: relative;
  .add {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    top: 8px;
    left: 210px;
  }
  .reduce {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    bottom: 8px;
    left: 210px;
  }
}
.lineManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  position: relative;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    height: 114px;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 90px !important;
      }
      .col-1 {
        width: 90px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 110px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 110px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .showDetials {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .showParams {
      width: 345px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .paramsItem {
        width: 115px;
        height: 70px;
        .items {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 15px;
        }
      }
      p {
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
      }
      .paramsItem:nth-child(1) {
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(2) {
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(3) {
        border-bottom: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(4) {
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
      .paramsItem:nth-child(5) {
        border-right: 1px solid #f2f2f2;
        box-sizing: border-box;
      }
    }
  }
}
</style>
