<template>
  <!-- 设置途经点线路 -->
  <div class="stationPopup">
    <el-table ref="myTable" :data="tableData" row-key="id" class="my-table">
      <el-table-column
        v-if="!isOperationMode"
        align="center"
        :resizable="false"
        width="70"
        class="dds"
      >
        <i class="iconfont its_sm_tuozhuai" />
      </el-table-column>
      <el-table-column
        align="center"
        type="index"
        label="序号"
      ></el-table-column>
      <el-table-column prop="stationName" label="途经点名称" width="220">
        <template slot-scope="scope">
          <el-select
            v-if="scope.row.canInput"
            v-model="scope.row.stationName"
            clearable
            filterable
            placeholder="请输入搜索选择"
            :filter-method="stationFilter"
            remote
            @change="handleSelect($event, scope.row, scope.$index)"
          >
            <el-option
              v-for="item in searchStation"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span v-else>{{ scope.row.stationName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="距离起点站票价">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.canInput"
            v-model="scope.row.price"
            type="number"
            clearable
            placeholder="请输入票价"
          ></el-input>
          <span v-else>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="!isOperationMode" width="140" label="操作">
        <template slot-scope="{ row, $index }">
          <el-button
            v-if="!row.isNew"
            type="text"
            size="small"
            sort="primary"
            @click="updateFun(row, $index)"
            >编辑</el-button
          >
          <el-button
            v-if="!row.isNew"
            type="text"
            size="small"
            sort="danger"
            @click="deleteFun(row, $index)"
            >删除</el-button
          >
          <!-- 最后一行操作 -->
          <el-button
            v-if="row.isNew"
            type="text"
            size="small"
            sort="primary"
            @click="makeSureFun(row, $index)"
          >
            <i
              class="iconfont its_queren"
              style="font-size: 20px; color: rgb(19, 206, 102)"
            ></i>
          </el-button>
          <el-button
            v-if="row.isNew"
            type="text"
            size="small"
            sort="primary"
            @click="clearFun(row, $index)"
          >
            <i
              class="iconfont its_quxiao"
              style="font-size: 20px; color: #ff6060"
            ></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { stationList } from "@/api/lib/customizedBus.js";
export default {
  components: {
    Sortable,
  },
  props: {
    stationData: {
      type: Array,
      default: () => [],
    },
    isOperationMode: {
      type: Boolean,
      default: false,
    },
    stationStartList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      oldSeqIndex: "",
      newSeqIndex: "",
      tableData: [],
      searchStation: [], //站点搜索结果
    };
  },
  methods: {
    clearFun(row, index) {
      this.tableData.splice(index, 1, { ...row, stationName: "", id: "" });
      this.searchStation = [...this.stationStartList];
    },
    handleSelect(v, row, index) {
      let ele = this.searchStation.find((item) => item["id"] == v);
      this.tableData.splice(index, 1, {
        ...row,
        stationName: ele.name,
        stationId: v,
      });
      console.log(this.tableData);
    },
    stationFilter(query) {
      if (query !== "") {
        setTimeout(() => {
          let data = { keyWord: query };
          stationList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.searchStation = res.data;
            }
          });
        }, 200);
      } else {
        this.searchStation = [];
      }
    },
    makeSureFun(row, index) {
      if (!this.tableData[index].stationName || !this.tableData[index].price) {
        this.$message.warning("请填写完站点名称和票价！");
        return;
      }
      this.tableData.splice(index, 1, {
        ...row,
        canInput: false,
        isNew: false,
      });
      this.tableData.push({
        createTime: null,
        id: null,
        lineId: this.tableData[index].lineId,
        price: "",
        serialNumber: this.tableData.length + 1,
        stationCode: "",
        stationId: null,
        stationName: "",
        updateTime: null,
        canInput: true, //可输入
        isNew: true,
      });
      this.searchStation = [...this.stationStartList];
    },
    updateFun(row, index) {
      this.tableData.splice(index, 1, { ...row, canInput: true });
    },
    deleteFun(row, index) {
      this.tableData.splice(index, 1);
    },
    //行拖拽
    openDialog() {
      const tbody = document.querySelector(
        ".my-table .el-table__body-wrapper tbody"
      );
      const _this = this; // Sortable的其他属性或方法使用可参考官方文档
      Sortable.create(tbody, {
        animation: 300,
        easing: "cubic-bezier(1, 0, 0, 1)",
        handle: ".its_sm_tuozhuai", // handle控制拖拽的区域,CSS样式控制
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
          //修改sequence排序
          if (oldIndex < newIndex) {
            for (let i = 0; i < newIndex - oldIndex; i++) {
              _this.tableData[i + oldIndex].sequence--;
            }
            _this.tableData[newIndex].sequence = newIndex + 1;
          } else {
            for (let i = oldIndex; i > newIndex; i--) {
              _this.tableData[i].sequence++;
            }
            _this.tableData[newIndex].sequence = newIndex + 1;
          }
        },
      });
    },
  },
  mounted() {
    this.tableData = [...this.stationData];
    this.searchStation = [...this.stationStartList];
    this.openDialog();
  },
};
</script>

<style scoped lang="scss">
.passengerBusinessModelConfig {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 20px 0px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  justify-content: center;
}
.stationPopup {
  height: 50vh;
}
/deep/.el-radio__label {
  font-size: 0;
}
.its_sm_tuozhuai:hover {
  color: #409eff;
}
.its_sm_tuozhuai:before {
  content: "\e7f4";
  font-size: 18px;
}
</style>
